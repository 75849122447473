getPage = (url) ->
	pattern = new RegExp('(p=)(.*?)(&|$)')
	res = pattern.exec(url)
	if res
		return parseInt res[2]
	else
		i = url.indexOf('?')
		if i < 0
			i = url.indexOf('#')
			if i < 0
				i = url.length
		url = url.substring(0, i)
		s = url.lastIndexOf(',')
		if s > -1
			return parseInt url.substr(s + 1)
	1

replaceUrlParam = (url, paramName, paramValue) ->
	pattern = new RegExp('(' + paramName + '=).*?(&|$)')
	newUrl = url
	if url.search(pattern) >= 0
		newUrl = url.replace(pattern, '$1' + paramValue + '$2')
	else
		newUrl = newUrl + (if newUrl.indexOf('?') > 0 then '&' else '?') + paramName + '=' + paramValue
	newUrl

$ ->
	$('body').on 'click', '#print-catalogue', (e) ->
		$('#pages-message').hide()
		code = $(e.target).data('code')
		$form = $(this).closest('.form')
		pages = $form.find('input[name=pages]').val()
		if !$.isNumeric(pages)
			$('#pages-message').html("Vă rugăm să introduceți un număr de pagini valid").show()
			return
		window.location.href = "/print/catalogue/a4/carti/" + code + "/" + pages
		return false
	$('#subject-to-newsletter').on 'click', (e) ->
		id = $(e.target).data('id')
		nid = $(e.target).data('nid')
		if App.is_logged || nid != ""
			$.post '/add-to-newsletter', {
				id: id, type: 'subject'
			}, (json) ->
				trpop.add {
					title: _('Newsletter')
					content: json.message
					type: (if json.success then 'notice' else 'warning') }
		else
			loadInLightbox {
				url: '/insert-email/' + id + '/subject'
				centered: true }
		return false
	$('body').on 'click', '#insert-subject', (e) ->
		$('#email-error-message').hide()
		$form = $(this).closest('.form')
		email = $form.find('input[name=email-input-newsletter]').val()
		if !email.match Backbone.Validation.patterns.email
			$('#email-error-message').html("Vă rugăm să introduceți un email valid").show()
			return
		$(this).closest('.box').trigger('close')
		id = $form.find('input[name=email-input-newsletter]').attr('data-id')
		type = $form.find('input[name=email-input-newsletter]').attr('data-type')
		App.addToNewsletter(id, type)
		return false

	$('#contributor-to-newsletter').on 'click', (e) ->
		id = $(e.target).data('id')
		nid = $(e.target).data('nid')
		if App.is_logged || nid != ""
			$.post '/add-to-newsletter', {
				id: id, type: 'contributor'
			}, (json) ->
				trpop.add {
					title: _('Newsletter')
					content: json.message
					type: (if json.success then 'notice' else 'warning') }
		else
			loadInLightbox {
				url: '/insert-email/' + id + '/contributor'
				centered: true }
		return false
	$('.list h2').dotdotdot { watch: 'window' }
	$('#sort>select').on 'change', ->
		location.assign replaceUrlParam(window.location.href, 'o', $('#sort>select').val())
		return

	$("#filters").on 'click', ->
		console.log 'filters click'
		if $("#facets").hasClass 'small-facets'
			$("#results-list").show()
			$("#facets").hide()
			$("#facets").removeClass 'small-facets'
			$("#filters").html('Filtrare')
		else
			$("#facets").addClass 'small-facets'
			$("#facets").show()
			$("#results-list").hide()
			$("#filters").html('<span class="color-theme-5">Închide</span>')
		return false
	return
